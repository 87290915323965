import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Collapse, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/faq.json')
            .then((response) => response.json())
            .then((data) => {
                setFaqData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Ошибка загрузки FAQ:', error);
                setLoading(false);
            });
    }, []);

    return (
        <FAQContainer>
            <Title>Часто задаваемые вопросы</Title>
            {loading ? (
                <SpinContainer>
                    <Spin size="large" />
                </SpinContainer>
            ) : (
                <StyledCollapse
                    expandIcon={({ isActive }) => <StyledIcon rotate={isActive ? 180 : 0} />}
                    expandIconPosition="end"
                    bordered={false}
                    accordion
                    items={faqData.map((item, index) => ({
                        key: index,
                        label: item.question,
                        children: (
                            <AnswerText
                                dangerouslySetInnerHTML={{ __html: item.answer }}
                            />
                        ), // Используем dangerouslySetInnerHTML для рендеринга HTML
                    }))}
                />
            )}
        </FAQContainer>
    );
};

export default FAQ;

// Стили

const FAQContainer = styled.div`
    padding: 30px;
    background: linear-gradient(145deg, #e0f7ff, #f4faff);
    border-radius: 16px;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 40px auto;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    }
`;

const Title = styled.h2`
    color: #004aad;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const SpinContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-item {
        border-radius: 12px;
        margin-bottom: 16px;
        border: none;
        background: #fff;
        overflow: hidden;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
        }
    }

    .ant-collapse-header {
        font-size: 20px;
        font-weight: 600;
        color: #003366;
        background: linear-gradient(135deg, #fff, #e8f3ff);
        padding: 16px 24px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        transition: background 0.3s;
        text-align: left; /* Выровнять по левому краю */

        &:hover {
            background: linear-gradient(135deg, #f0f8ff, #dbe9ff);
        }
    }

    .ant-collapse-content {
        background: #f9fbff;
    }
`;

const StyledIcon = styled(DownOutlined)`
    color: #0077b6;
    font-size: 22px;
    transition: transform 0.3s ease;
`;

const AnswerText = styled.div`
    font-size: 16px;
    color: #333;
    line-height: 1.7;
    background: linear-gradient(135deg, #f4faff, #e0f7ff);
    padding: 12px 18px;
    border-left: 4px solid #0077b6;
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeIn 0.5s ease forwards;
    text-align: left; /* Выровнять текст по левому краю */

    @keyframes fadeIn {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;
