import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';  // Импортируем иконку Telegram из react-icons

const TelegramButton = () => {
  // Стили кнопки
  const buttonStyle = {
    position: 'relative',
    marginTop: '20px',  // Отступ сверху для расположения ниже FAQ
    marginBottom: '20px',  // Добавляем отступ снизу
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#0088cc',  // Основной цвет кнопки
    width: '60px',  // Фиксированная ширина
    height: '60px',  // Фиксированная высота
    padding: '0',  // Убираем паддинг, чтобы кнопка оставалась круглой
    borderRadius: '50%',  // Делаем кнопку круглой
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    outline: 'none',  // Убираем стандартную обводку при фокусе
  };

  const iconStyle = {
    color: 'white',  // Цвет иконки
  };

  return (
    <a 
      href="https://t.me/seafood_ru" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={buttonStyle}
    >
      <FaTelegramPlane size={30} style={iconStyle} />  {/* Размер иконки */}
    </a>
  );
};

export default TelegramButton;
