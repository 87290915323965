import React from 'react';
import './App.css';
import TariffTable from './components/TariffTable';
import FAQ from './components/FAQ';
import logo from './assets/logo.png'; // Import logo image
import TelegramButton from './components/TelegramButton';  // Import Telegram button component

function App() {
  return (
    <div className="App">
      <div className="header">
        <a href="https://t.me/seafood_ru" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Логотип" className="logo" /> {/* Logo with link */}
        </a>
        <h1 className="title">Тарифы</h1> {/* Header title */}
      </div>
      <TariffTable />
      <FAQ />
      <TelegramButton /> {/* Telegram Button */}
    </div>
  );
}

export default App;
