import React, { useState, useEffect } from 'react';
import { Table, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faInfinity } from '@fortawesome/free-solid-svg-icons';

const TariffTable = () => {
    const [tariffData, setTariffData] = useState([]);
    const [isYearly, setIsYearly] = useState(false);

    useEffect(() => {
        fetch('/tariffs.json')
            .then((response) => response.json())
            .then((data) => setTariffData(data.map((item, index) => ({ ...item, key: index }))))
            .catch((error) => console.error('Ошибка загрузки тарифных данных:', error));
    }, []);

    const renderAvailability = (value, tariffName) => {
        const buttonColor = isYearly ? '#3f51b5' : '#4caf50';

        if (value === 'да') {
            return <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '18px' }} />;
        }
        if (value === 'нет') {
            return <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '18px' }} />;
        }
        if (value === 'безлимит') {
            return <FontAwesomeIcon icon={faInfinity} style={{ color: '#1890ff', fontSize: '18px' }} />;
        }

        // Если значение — "Самопосты" или "Автопосты", применяем стиль с большим шрифтом
        if (value && (value.includes("Самопосты") || value.includes("Автопосты"))) {
            return <HighlightedWord>{value}</HighlightedWord>;
        }

        if (typeof value === 'string' && value.includes('р')) {
            const price = value.replace('р', '').trim();
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledButton onClick={() => handleBuyClick(tariffName)} style={{ backgroundColor: buttonColor }}>
                        {isYearly && <DiscountBadge>-25%</DiscountBadge>}
                        <div>Купить</div>
                        <div>{price} <span style={{ whiteSpace: 'nowrap' }}>₽/мес.</span></div>
                    </StyledButton>
                </div>
            );
        }
        return value;
    };

    const getPrice = (price) => {
        if (typeof price === 'object') {
            return isYearly ? price['3 месяца'] : price['месяц'];
        }
        return price;
    };

    const handleBuyClick = (tariffName) => {
        const message = `Здравствуйте, хочу оформить подписку на тариф ${tariffName}`;
        const telegramUrl = `https://t.me/Seafooder?text=${encodeURIComponent(message)}`;
        window.open(telegramUrl, '_blank');
    };

    const columns = [
        {
            title: 'Опции',
            dataIndex: 'feature',
            key: 'feature',
            render: (text, record) => (
                <span>
                    {record.description ? (
                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: record.description }} />} placement="top">
                            <span>
                                {text}{' '}
                                <QuestionCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                            </span>
                        </Tooltip>
                    ) : (
                        text
                    )}
                    {record.link && record.linkText && (
                        <LinkContainer>
                            <StyledLink href={record.link} target="_blank" rel="noopener noreferrer">
                                {record.linkText}
                            </StyledLink>
                        </LinkContainer>
                    )}
                </span>
            ),
        },
        {
            title: 'Стандарт',
            dataIndex: 'standard',
            key: 'standard',
            render: (value) => renderAvailability(value, 'Стандарт'),
        },
        {
            title: 'Лайт',
            dataIndex: 'light',
            key: 'light',
            render: (value) => renderAvailability(getPrice(value), 'Лайт'),
        },
        {
            title: 'Бизнес',
            dataIndex: 'business',
            key: 'business',
            render: (value) => renderAvailability(getPrice(value), 'Бизнес'),
        },
        {
            title: 'Бизнес+',
            dataIndex: 'businessPlus',
            key: 'businessPlus',
            render: (value) => renderAvailability(getPrice(value), 'Бизнес+'),
        },
    ];

    return (
        <StyledTableContainer>
            <StyledTable
                columns={columns}
                dataSource={tariffData}
                pagination={false}
                rowClassName={(record) => {
                    // Здесь проверяем, есть ли свойство `rowColor` для строк
                    if (record.feature === 'Стоимость') {
                        return 'pricing-row'; // Применяем стиль для строк с ценами
                    }
                    return record.rowColor === 'pink' ? 'pink-row' : 'blue-row'; // Для других строк, задаём цвета
                }}
                sticky
            />
            <ButtonContainer>
                <StyledPeriodButton
                    $active={!isYearly}
                    onClick={() => setIsYearly(false)}
                    period="month"
                >
                    Месяц
                </StyledPeriodButton>
                <StyledPeriodButton
                    $active={isYearly}
                    onClick={() => setIsYearly(true)}
                    period="threeMonths"
                >
                    Три месяца
                    <DiscountBadge>-25%</DiscountBadge>
                </StyledPeriodButton>
            </ButtonContainer>
        </StyledTableContainer>
    );
};

export default TariffTable;

// Стили для гиперссылки
const LinkContainer = styled.div`
    margin-top: 8px;
    text-align: left;  /* Выравнивание по левому краю */
`;

const StyledLink = styled.a`
    color: #1890ff;  /* Синий цвет ссылки */
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease, text-decoration 0.2s ease;

    &:hover {
        color: #1d63d0;  /* Темнее при наведении */
        text-decoration: underline;  /* Подчеркивание при наведении */
    }

    &:focus {
        outline: none;  /* Убираем обводку при фокусе */
    }
`;

const StyledTableContainer = styled.div`
    padding-left: 50px;   /* Отключаем левый отступ */
    padding-right: 50px;  /* Отключаем правый отступ */
    padding-top: 0px; /* Вертикальные отступы остаются без изменений */
    padding-bottom: 50px; /* Вертикальные отступы остаются без изменений */
    background-color: #ffffff;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;

    @media (max-width: 885px) {
        padding-left: 0;   /* Отключаем левый отступ */
        padding-right: 0;  /* Отключаем правый отступ */
        padding-top: 0px; /* Вертикальные отступы остаются без изменений */
        padding-bottom: 50px; /* Вертикальные отступы остаются без изменений */
    }
`;

const StyledTable = styled(Table)`
    .pink-row {
        background-color: rgb(240, 248, 255) !important;
    }

    .blue-row {
        background-color: rgb(255, 245, 238) !important;
    }
    .ant-table {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        margin: 0; /* Убираем любые внешние отступы у таблицы */
    }
    .ant-table-thead > tr > th {
        background-color: #0088cc;
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        padding-left: 6px;  /* Устанавливаем левый отступ в 4px для заголовков */
        padding-right: 4px;  /* Устанавливаем правый отступ в 4px для заголовков */
    }
    .ant-table-tbody > tr > td {
        text-align: center;
        vertical-align: middle;
        font-size: 16px; /* Размер шрифта для обычных ячеек */
        padding-left: 6px;    /* Устанавливаем левый отступ в 4px для ячеек */
        padding-right: 0;     /* Убираем правый отступ */
    }
    .ant-table-tbody > tr > td:first-child {
        text-align: left;  /* Выровнять первый столбец по левому краю */
    }
    .ant-table-tbody > tr > td:last-child {
        padding-right: 6px; /* Устанавливаем правый отступ в 4px для последнего столбца */
    }
    .ant-table-tbody > tr:hover > td:not(.pricing-row) {
        background-color: #e3f2fd;
        transition: background-color 0.3s ease;
    }
    .feature-row {
        background-color: #ffffff;
        transition: background-color 0.2s ease;
    }
    .pricing-row {
        background-color: #e1f5fe;
        color: #333;
        font-weight: bold;
        text-align: center;
        padding: 12px 0;
        border-top: 1px solid #b0bec5;
        border-bottom: 1px solid #b0bec5;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s;
    }
    .pricing-row:hover {
        background-color: #e1f5fe;
        cursor: default;
    }

    @media (max-width: 785px) {
        .ant-table {
            padding-left: 0;  /* Убираем левый отступ */
            padding-right: 0; /* Убираем правый отступ */
            margin: 0; /* Убираем любые внешние отступы у таблицы */
        }
        .ant-table-thead > tr > th {
            padding-left: 6px;  /* Устанавливаем левый отступ в 6px для заголовков */
            padding-right: 4px;  /* Устанавливаем правый отступ в 4px для заголовков */
        }
        .ant-table-tbody > tr > td {
            padding-top: 12px;    /* Оставляем отступ сверху */
            padding-bottom: 12px; /* Оставляем отступ снизу */
            padding-left: 6px;    /* Устанавливаем левый отступ в 4px для ячеек */
            padding-right: 0;     /* Убираем правый отступ */
        }
    }

    /* Медиазапрос для уменьшения размера шрифта на экранах менее 485px */
    @media (max-width: 495px) {
        .ant-table-tbody > tr > td {
            font-size: 14px; /* Уменьшаем размер шрифта для ячеек */
        }
        .ant-table-thead > tr > th {
            font-size: 16px; /* Уменьшаем размер шрифта для заголовков */
        }
    }

    /* Новый медиазапрос для уменьшения размера шрифта на экранах менее 430px */
    @media (max-width: 440px) {
        .ant-table-tbody > tr > td {
            font-size: 12px; /* Уменьшаем размер шрифта для ячеек */
        }
        .ant-table-thead > tr > th {
            font-size: 14px; /* Уменьшаем размер шрифта для заголовков */
        }
    }

    /* Новый медиазапрос для уменьшения размера шрифта на экранах менее 375px */
    @media (max-width: 385px) {
        .ant-table-tbody > tr > td {
            font-size: 10px; /* Уменьшаем размер шрифта для ячеек */
        }
        .ant-table-thead > tr > th {
            font-size: 12px; /* Уменьшаем размер шрифта для заголовков */
        }
    }
`;


const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const StyledPeriodButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'period'
})`
  background-color: ${({ $active, period }) =>
    $active
      ? period === 'month'
        ? '#4caf50'
        : '#3f51b5'
      : '#e1e1e1'};
  color: ${({ $active }) => ($active ? 'white' : 'black')};
  border: none;
  border-radius: 12px;
  padding: 10px 16px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  position: relative; // Позиционирование для значка скидки
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ $active, period }) =>
      $active
        ? period === 'month'
          ? '#388e3c'  // Темный зеленый для месяца
          : '#303f9f'  // Темный синий для трех месяцев
        : '#d1d1d1'};   // Темный серый при наведении на неактивную кнопку
  }

  &:focus {
    outline: none;
  }
`;


const StyledButton = styled.button`
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 12px 16px 16px; /* Добавлен нижний отступ, чтобы "Купить" и цена расположились ниже */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: #303f9f;
        transform: scale(1.05);
    }

    &:focus {
        outline: none;
    }

    div {
        line-height: 1.2;
        text-align: center;
    }

    @media (max-width: 450px) {
        padding: 8px;
    }

    /* Новый медиа-запрос для уменьшения шрифта на экранах меньше 400px */
    @media (max-width: 400px) {
        font-size: 14px; /* Уменьшение размера шрифта */
    }
`;

// Значок скидки -25%, позиционированный выше
const DiscountBadge = styled.div`
    position: absolute;
    top: -8px; /* Смещаем значок выше */
    right: 10px; /* Устанавливаем отступ справа, чтобы не перекрывать текст */
    background-color: #ff5252;
    color: white;
    border-radius: 8px;
    padding: 4px 6px;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    line-height: 1;
`;

// Стили для выделенных слов
const HighlightedWord = styled.span`
    font-size: 20px;  /* Большой шрифт */
    font-weight: bold;  /* Жирное начертание */
    transition: font-size 0.2s ease;

    &:hover {
        font-size: 22px;  /* Увеличиваем размер шрифта при наведении */
    }
    @media (max-width: 660px) {
        font-size: 20px;  /* Большой шрифт */
        &:hover {
            font-size: 20px;  /* Увеличиваем размер шрифта при наведении */
        }
    }
    @media (max-width: 600px) {
        font-size: 16px;  /* Большой шрифт */
        &:hover {
            font-size: 16px;  /* Увеличиваем размер шрифта при наведении */
        }
    }
    @media (max-width: 490px) {
        font-size: 14px;  /* Большой шрифт */
        &:hover {
            font-size: 14px;  /* Увеличиваем размер шрифта при наведении */
        }
    }
    @media (max-width: 435px) {
        font-size: 12px;  /* Большой шрифт */
        &:hover {
            font-size: 12px;  /* Увеличиваем размер шрифта при наведении */
        }
    }
    @media (max-width: 380px) {
        font-size: 10px;  /* Большой шрифт */
        &:hover {
            font-size: 10px;  /* Увеличиваем размер шрифта при наведении */
        }
    }
`;